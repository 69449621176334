@import '../theme.less';

body {
    background-color: @body_bg;
    margin: 0;
    line-height: 1;
    color: @font_color;
}
#app {
    margin-left: auto;
    margin-right: auto;
    width: 1280px;
    background-color: @app_bg;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    > .content {
        flex-grow: 1;
    }
    > .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        > div {
            display: flex;
            gap: 15px;
            > a {
                color: rgba(0, 0, 0, 0.5);
                text-decoration: none;
            }
        }
    }
}

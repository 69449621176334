@import '../theme.less';

.nav-menu {
    display: flex;
    justify-content: space-between;
    background: @nav_bg;
    margin: 15px;
    >div {
        display: flex;
    }
}

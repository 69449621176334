.spin {
    /* Define the keyframes for rotation */
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    animation: spin 2s linear infinite; /* Rotate every 2 seconds */
}

@import '../animation/fade.less';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    > .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
    > .modal-content {
        position: relative;
        z-index: 1000;
        padding: 15px;
        background-color: white;
        min-width: 300px;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        > .modal-title {
            border-bottom: 1px solid #ccc;
            font-size: 16px;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }
        > .modal-body {
            flex-grow: 1;
        }
        > .modal-footer {
            margin-top: 15px;
            padding-top: 15px;
            justify-content: right;
            display: flex;
        }
    }
}

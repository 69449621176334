@import "../../theme.less";
@import "../animation/spin.less";

.button {
    padding: 10px 25px;
    border: 1px solid @color_primary;
    cursor: pointer;
    user-select: none;
    background: @button_bg;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    &.link {
        background-color: transparent;
        color: @link_color;
        padding: 0;
        border: none;
        &:hover {
            background-color: transparent;
        }
    }
    &.primary {
        background-color: @button_primary_bg;
        color: @button_primary_font;
        &:hover {
            background-color: fadeout(@button_primary_bg, 10%);
        }
    }
    &:hover(:not(.disabled)) {
        background-color: @button_hover_bg;
    }
    &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
    }
    > .loading-icon {
        margin-left: 3px;
        height: 16px;
    }
}

.buttons {
    display: flex;
    gap: 10px;
}

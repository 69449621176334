@import "../animation/spin.less";

.loading-modal {
    > .loading-wrap {
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: center;
        color: white;
        z-index: 1001;
        > .loading-icon {
            width: 20px;
            height: 20px;
        }
    }
}

.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    padding: 15px;
    white-space: nowrap;
    > .icon {
        width: 16px;
        height: 16px;
        margin: 0 5px;
    }
    > .submenu {
        display: none;
    }
    &:hover {
        background-color: #f7f6f5;
        > .submenu {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 100%;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            background-color: #f0edeb;
        }
    }
}

.fade-in-start {
    opacity: 0;
    transition: opacity 300ms ease-out;
}
.fade-in-end {
    opacity: 1;
}
.fade-out-start {
    opacity: 1;
    transition: opacity 300ms ease-out;
}
.fade-out-end {
    opacity: 0;
}
